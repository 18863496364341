export const INTEGER = "NUMBER_0";
export const DECIMAL_1 = "NUMBER_1";
export const DECIMAL_2 = "NUMBER_2";
export const DECIMAL_3 = "NUMBER_3";
export const DECIMAL_4 = "NUMBER_4";

export const DECIMAL_POINTER = process.env.REACT_APP_DECIMAL_POINTER ? process.env.REACT_APP_DECIMAL_POINTER : ".";
export const THOUSAND_DIGIT_SYMBOL = process.env.REACT_APP_THOUSAND_DIGIT_SYMBOL ? process.env.REACT_APP_THOUSAND_DIGIT_SYMBOL : ",";

/**
 * Format the number string to request format string
 * @param numberStr the source value
 * @param decimalPlaces the decimal digit
 * @returns 
 */
export const formatNumberStr = (numberStr: string, decimalPlaces: number, autoPadEnd:boolean=true,
  showDecimalPointerAlways:boolean=false
): string => {
    if (numberStr===null || numberStr==='') return '';
    
    //remove the thousand digit symbol before validate number
    const regex = new RegExp(`\\${THOUSAND_DIGIT_SYMBOL}`, 'g');
    numberStr = numberStr.replace(regex, '');

    const hasDecimalPointer = numberStr.includes(DECIMAL_POINTER);
    
    const parts = numberStr.split(DECIMAL_POINTER);  
    const integerPart = parts[0] || '0';

    //Trim excess decimal places beyond the configured limit.
    let decimalPart = parts[1] && parts[1].length >= decimalPlaces ? parts[1].substring(0, decimalPlaces) : 
      ( parts[1] ? parts[1].substring(0, parts[1].length) : '');
    decimalPart = decimalPart ? (autoPadEnd ? decimalPart.padEnd(decimalPlaces, '0') : decimalPart) : (autoPadEnd ? ''.padEnd(decimalPlaces, '0') : '');

    const formattedDecimalPart = decimalPart.replace('.', DECIMAL_POINTER);
    const integerPartWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DIGIT_SYMBOL);
    
    return `${integerPartWithSeparator}${formattedDecimalPart? `,${formattedDecimalPart}` : (showDecimalPointerAlways && hasDecimalPointer ? ',' : '')}`;
}

/**
 * Format the number for input component
 * @param num 
 * @param decimalPlaces 
 * @returns 
 */
export const formatNumber = (num: number, decimalPlaces: number): string => {
    if (num===null) return '';

    num = Math.round((num) * 100) / 100;
    const parts = num.toString().split('.');  
    const integerPart = parts[0] || '0';  
    const decimalPart = parts[1] ? parts[1].padEnd(decimalPlaces, '0') : ''.padEnd(decimalPlaces, '0');

    const formattedDecimalPart = decimalPart.replace('.', DECIMAL_POINTER);
    const integerPartWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DIGIT_SYMBOL);

    return `${integerPartWithSeparator}${formattedDecimalPart? `,${formattedDecimalPart}` : ''}`;
}

/**
 * format number for table
 * @param num 
 * @param decimalString 
 * @returns 
 */
export const formatNumberWithFormatString = (num: number, decimalString: string): string => {
    if (num===null) return '';
    if (!!!decimalString || !decimalString.includes("_")) return '';
    
    let decimalArr = decimalString.split("_");
  
    const parts = num.toString().split('.');  
    const integerPart = parts[0] || '0';  
    const decimalPart = parts[1] && decimalArr[1] ? parts[1].padEnd(((decimalArr[1] as unknown as number)), '0') : ''.padEnd((decimalArr[1] as unknown as number), '0');

    const formattedDecimalPart = decimalPart.replace('.', DECIMAL_POINTER);
    const integerPartWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSAND_DIGIT_SYMBOL);

    return `${integerPartWithSeparator}${formattedDecimalPart? `,${formattedDecimalPart}` : ''}`;  
}

/**
 * convert the number string to number
 * @param numberStr 
 * @returns 
 */
export const formatStrToNumber = (numberStr:string):number => {
  let cleanedStr = numberStr.replace(/\./g, '');
  let formattedStr = cleanedStr.replace(/,/g, '.');
  let number = parseFloat(formattedStr);  

  return number;
}

export const formatStrToNumberForCom = (numberStr:string):number => {
  let cleanedStr = numberStr.replace(/,/g, '');
  let formattedStr = cleanedStr.replace(/\./g, '.');
  let number = parseFloat(formattedStr);  

  return number;
}

/**
 * validate if the user input value is correctly or not
 * @param numberStr 
 * @param decimalPlaces 
 * @returns 
 */
export const validateNumberStr = (numberStr: string, decimalPlaces:number):boolean => {
  //let regex = new RegExp(`^-?\\d*(?:\\${THOUSAND_DIGIT_SYMBOL}\\d{3})*?(${DECIMAL_POINTER}\\d*)?\\${DECIMAL_POINTER}?$`);
  let regex = new RegExp(`^[\\d]*[,]?[\\d]{0,${decimalPlaces}}$`);
  const match = numberStr.match(regex);

  return match !== null;
}

/**
 * convert the number string to number
 * @param numberStr 
 * @returns 
 */
export const formatStrForEditable = (numberStr:string):string => {
  let cleanedStr = numberStr.replace(/\./g, '');

  return cleanedStr;
}