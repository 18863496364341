import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CalendarEventMaintenanceConstant } from "./CalendarEventMaintenanceConstant";
const SCREEN_CONSTANT = CalendarEventMaintenanceConstant; 
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_CALENDAR_EVENT_COL_DEF: any[] = [ 
        { 
            headerName: SCREEN_CONSTANT.EVENT_TYPE, 
            field: "eventType", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 180, 
            //pinned: "left", 
            //checkboxSelection: true, 
            //headerCheckboxSelection: true, 

        }, 
        { 
            headerName: SCREEN_CONSTANT.EVENT_DATE, 
            field: "eventDate", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 150, 
            dataType: "date"
        }, 
        { 
            headerName: SCREEN_CONSTANT.DESC, 
            field: "eventDesc", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 200, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.FSP_ADJ, 
            field: "fspAdjustmentInd", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 180, 
        }, 
        { 
            headerName: SCREEN_CONSTANT.ACTIVE_IND, 
            field: "activeInd", 
            enableRowGroup: false, 
            enablePivot: true, 
            enableValue: false, 
            rowGroup: false, 
            filter: false, 
            width: 130, 
        }, 
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}

