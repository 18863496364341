import { SelectionChangedEvent } from "ag-grid-community";
import { CalendarEventEntity } from "domain/entity/CalendarEvent/CalendarEventEntity";
import { INITIAL_CALENDAR_EVENT_COL_DEF } from "presentation/constant/CalendarEvent/CalendarEventMaintenanceColumnDefinition";
import { CalendarEventMaintenanceConstant } from "presentation/constant/CalendarEvent/CalendarEventMaintenanceConstant";
import { useCalendarEventMaintenanceVM } from "presentation/hook/CalendarEvent/useCalendarEventMaintenanceVM";
import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";
const CalendarEventTablePanel:React.FC = () => { 
    const [calendarEventState] = useCalendarEventMaintenanceTracked(); 
    const calendarEventVM = useCalendarEventMaintenanceVM(); 
    const gridRef: any = useRef(null); 
    const {tableData} = calendarEventState; 
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {      
        const selectedRows = e.api.getSelectedRows(); 
        calendarEventVM.updateSelectedRows(tableData,selectedRows); 
    }, [calendarEventVM, tableData]) 
    const handleAddClick = useCallback(() => { 
        calendarEventVM.onAdd(); 
    }, [calendarEventVM]) 
    const handleRowDoubleClick = useCallback((entity: CalendarEventEntity) => { 
          calendarEventVM.onEdit(entity); 
    }, [calendarEventVM]) 
    const memoTable = useMemo(() => { 
        return ( 
        <HPHTable 
            id='calendar-event-table' 
            headerLabel={CalendarEventMaintenanceConstant.TITLE} 
            isNewColumnSetting={true} 
            columns={INITIAL_CALENDAR_EVENT_COL_DEF} 
            data={tableData??[]} 
            showPaginator={false} 
            editable={false} 
            showAddIcon={true} 
            onAddClick={handleAddClick} 
            showDeleteButton={false} 
            showReloadIcon={false} 
            isScrollHighlighted={true} 
            selectionMode={false} 
            isRowHighligted={true} 
            onSelectionChanged={handleSelectionChange} 
            onRowDoubleClick={(e: any, entity: CalendarEventEntity) => handleRowDoubleClick(entity)} 
            gridHeight="customHeight" 
            customHeight="calc(100vh - 75px)"  
            ref={gridRef} 
            /> 
        ); 
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick]) 
    return <><TableWrapper> 
            {memoTable} 
        </TableWrapper> 
        </>; 
} 
export default memo(CalendarEventTablePanel); 
